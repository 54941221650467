
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { DataTableColumn } from '@/models/DataTableColumn'
import payments from '@/services/payments'

import { currencyFilter } from '@/utils/string'

import CUCollectionTable from '@/components/CUCollectionTable.vue'
import { TableAction } from '@/models/TableAction'
import auth from '@/store/modules/auth'

@Component({
  components: { CUCollectionTable },
})
export default class PaymentsList extends mixins(DateMixin) {
  tableView = payments.tableView
  tableItemCount = 0

  handleUpdateCount(count: number): void {
    this.tableItemCount = count
  }

  actions: TableAction[] = []

  columns: DataTableColumn[] = [
    {
      _t_id: '7b37c1a4-e49a-497a-b442-253197c60981',
      text: 'Payment ID',
      value: 'paymentId',
    },
    {
      _t_id: '9da2bb38-7853-45f4-8e93-eb923dd13f75',
      text: 'Amount',
      value: 'amount',
      computedText: (row): string => currencyFilter(row.amount),
    },
    {
      _t_id: 'c0f88eb7-97cf-4900-8cce-7602588945f6',
      text: 'Payment Type',
      value: 'paymentType',
    },
    {
      _t_id: '1daaf6a8-5a7a-48ec-bead-08f87ecd20f4',
      text: 'Payment System',
      value: 'paymentSystem/label',
    },
    {
      _t_id: '40f03d9e-9329-46ed-9950-bf71364ee9e2',
      text: 'Reference Number',
      value: 'referenceNumber',
    },
    {
      _t_id: '529d5f34-e46d-4881-bb76-47468632218d',
      text: 'Reservation ID',
      value: 'credits.reservation.managedId',
      computedText: (row): string =>
        row.credits[0] ? row.credits[0].reservation.managedId : '',
    },
    {
      _t_id: '960ce001-8ddb-4f61-88d0-a8d391d8d92f',
      text: 'Created On',
      value: 'createdOn',
      computedText: (row): string =>
        this.formatMediumDate(row.createdOn, { tz: auth.getUserTimeZone }),
    },
    {
      _t_id: '623d5d4b-750c-4ca8-a5c9-dfc0e06fe492',
      text: 'Receipt ID',
      value: 'receiptId',
    },
    {
      _t_id: '68c882ed-7d1c-4474-a204-f5f7bd5b2819',
      text: 'Void',
      value: 'voidFlag',
      computedText: (row): string => (row.voidFlag ? 'True' : 'False'),
    },
  ]
}
